/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@ihs/components/Headings/SectionTitle';
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const IhsIntro = ({ sectionContent }) => {
    return (
        <Box width='100%' height="100%" mx="auto" px={{ base: "0", md: "20px", lg: "0" }} bg="#fff">
            <Box h="100%" py={{ base: "10px", md: "20px", lg: "42.54px" }} maxW={593} w="100%" mx="auto">
                <StructuredTextParser textAlign="center" str={render(sectionContent[0].body)} color='#484141'
                    fontFamily='Zilla Slab'
                    fontSize={{ base: "32px", md: '36px' }}
                    fontStyle='normal'
                    fontWeight='400'
                    mt="0"
                    lineHeight={{ base: "32px", md: '36px' }} />
                <SectionTitle titleObj={sectionContent[1]} textAlign="center" />
                <StructuredTextParser str={render(sectionContent[2].body)} fontSize="18px" lineHeight="32px" textAlign="center" />
            </Box>
        </Box>
    )
}

export default IhsIntro