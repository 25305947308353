/* Built In Imports */
/* External Imports */
import { Box, Heading, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the OurValues component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} OurValues component
 */

const OurValues = ({ sectionContent }) => {
  // console.log('sectionContent===', sectionContent);

  const colorBg = {
    brown: {
      md: 'https://static.sadhguru.org/d/46272/1702373367-home-like-nurturing-environment_tablet.jpg',
      lg: 'https://static.sadhguru.org/d/46272/1699521400-ihs-brown-desktop.svg',
    },
    green: {
      md: 'https://static.sadhguru.org/d/46272/1699521415-ihs-green-tab.svg',
      lg: 'https://static.sadhguru.org/d/46272/1699521403-ihs-green-desktop.svg',
    },
    blue: {
      md: 'https://static.sadhguru.org/d/46272/1702378624-blend-of-ancient-wisdom-and-contemporary-practice_tablet.jpg',
      lg: 'https://static.sadhguru.org/d/46272/1699521406-ihs-blue-desktop.svg',
    },
    red: {
      md: 'https://static.sadhguru.org/d/46272/1702373373-in-harmony-with-nature_tablet.jpg',
      lg: 'https://static.sadhguru.org/d/46272/1699521409-ihs-red-desktop.svg',
    },
  };

  return (
    <Box w="full" bg="#fff" px={{ base: '0px', md: '30px' }}>
      <Box mx="auto" maxW="1030px" w="full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          pb="20px"
        >
          <Heading
            as="h1"
            fontSize={{ base: '42px', md: '95px', lg: '146px' }}
            fontWeight="600"
            color="#EBDCAF"
            px={{ base: '0', lg: '0' }}
            pl={{ base: '36px', lg: '0' }}
            pos="relative"
            lineHeight={{ base: '40px', md: '96.5%' }}
          >
            {sectionContent?.[0]?.text}
          </Heading>
          <Image
            src={sectionContent[1]?.mobileImage?.url}
            w={{ base: '160px', md: '290px' }}
            alt=""
            h={{ base: '118px', md: '243px' }}
          />
        </Box>
        <MobileSection sectionContent={sectionContent} />
        <Box
          flexDir={{ base: 'row', md: 'column' }}
          display={{ base: 'none', md: 'block' }}
        >
          {sectionContent?.[2]?.card?.map((item, index) =>
            index % 2 ? (
              <Box
                key={nanoid(10)}
                display="flex"
                pb="50px"
                bgImage={{ md: colorBg[item.style], lg: colorBg[item.style] }}
                bgRepeat="no-repeat"
                bgPos="left bottom"
                bgSize={{ md: 'contain', lg: 'contain' }}
                maxW={{ md: '720px', lg: '100%' }}
                mx="auto"
                mb={{ md: '20px', lg: '30px' }}
              >
                <Box display="flex">
                  <Box
                    minH={{ md: '500px', lg: '400px' }}
                    p={{ md: '40px', lg: '70px' }}
                    mr={{ md: '100px', lg: '170px' }}
                  >
                    <Heading
                      as="h4"
                      color="#3B444B"
                      fontSize="36px"
                      fontFamily="Belanosima"
                      pb="0px"
                      fontWeight="600"
                    >
                      {' '}
                      {item?.title}
                    </Heading>
                    <StructuredTextParser
                      str={render(item?.description?.value)}
                      fontSize="18px"
                    />
                  </Box>
                </Box>
                <Image
                  src={item?.image?.url}
                  alt=""
                  h={{ md: '261px', lg: '360px' }}
                  pos="relative"
                  m={{ md: '50px 0px 0px -100px', lg: '50px 0px 0px -180px' }}
                  borderRadius="8px"
                />
              </Box>
            ) : (
              <Box
                key={nanoid(10)}
                display="flex"
                pb="50px"
                bgImage={{ md: colorBg[item.style], lg: colorBg[item.style] }}
                bgRepeat="no-repeat"
                bgPos="right bottom"
                bgSize={{ md: '77% 100%', lg: 'contain' }}
                maxW={{ md: '720px', lg: '100%' }}
                mx="auto"
                mb={{ md: '20px', lg: '30px' }}
              >
                <Image
                  src={item?.image?.url}
                  alt=""
                  h={{ md: '261px', lg: '360px' }}
                  pos="relative"
                  m={{ md: '50px -100px 0px 0px', lg: '50px -180px 0px 0px' }}
                  borderRadius="8px"
                />
                <Box display="flex">
                  <Box
                    minH={{ md: '500px', lg: '400px' }}
                    p={{ md: '40px', lg: '70px' }}
                    ml={{ md: '100px', lg: '180px' }}
                  >
                    <Heading
                      as="h4"
                      color="#3B444B"
                      fontSize="36px"
                      fontFamily="Belanosima"
                      pb="0px"
                      fontWeight="600"
                    >
                      {' '}
                      {item?.title}
                    </Heading>
                    <StructuredTextParser
                      str={render(item?.description?.value)}
                      fontSize="18px"
                    />
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

const MobileSection = ({ sectionContent }) => {
  const mobColorBg = {
    brown:
      'https://static.sadhguru.org/d/46272/1700207851-1699521428-ihs-brown-mobile.svg',
    green:
      'https://static.sadhguru.org/d/46272/1700207859-1699521431-ihs-green-mobile.svg',
    blue: 'https://static.sadhguru.org/d/46272/1700207854-1699521425-ihs-blue-mobile.svg',
    red: 'https://static.sadhguru.org/d/46272/1700207856-1699521436-ihs-red-mobile.svg',
  };
  return (
    <Box
      flexDir={{ base: 'row', md: 'column' }}
      display={{ base: 'block', md: 'none' }}
      px="15px"
      pb="30px"
    >
      {sectionContent?.[2]?.card?.map(item => (
        <Box
          key={nanoid(10)}
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          bgImage={mobColorBg[item.style]}
          bgRepeat="no-repeat"
          bgPos="center bottom"
          bgSize="contain"
          // bgPos="center"
          // bgSize="cover"
          maxW="320px"
          mx="auto"
          mb="30px"
        >
          <Image
            src={item?.image?.url}
            alt=""
            h={{ base: 'auto' }}
            pos="relative"
            borderRadius="8px"
          />
          <Box px="16px">
            <Heading
              as="h4"
              color="#3B444B"
              fontSize="26px"
              fontFamily="Belanosima"
              pt="20px"
              fontWeight="600"
            >
              {' '}
              {item?.title}
            </Heading>
            <StructuredTextParser
              str={render(item?.description?.value)}
              fontSize="18px"
              pb="100px"
              px="0px"
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OurValues;
