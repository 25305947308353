/* Built In Imports */

/* External Imports */
import { chakra } from '@chakra-ui/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/* Internal Imports */
/* Components */

/* Services */
/* Styles */
// import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * Renders LazyLoad Image Component
 *
 * @param {string} src - Source of the image
 * @returns {ReactElement} LazyLoad Image Component component
 */
const LazyLoadImageComponent = ({ ...props }) => {
    const ChakraLazyLoadImage = chakra(LazyLoadImage);

    // test const { afterLoad, beforeLoad } = LazyLoadImage;
    // consoleLog(afterLoad ? true : false);
    return (
        <>
            {/* <SkeletonUi isLoaded={beforeLoad ? false : true}> */}
            {/* <Skeleton isLoaded> */}
            <ChakraLazyLoadImage {...props} />
            {/* </Skeleton> */}
            {/* </SkeletonUi> */}
        </>
    );
};

export default LazyLoadImageComponent;
