/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import FlipCard from '@ihs/components/Cards/FlipCard';
import SingleLineText from '@ihs/components/Text/SingleLineText';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the WhatMakes component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} WhatMakes component
 */

const WhatMakes = ({ sectionContent }) => {
  // console.log(sectionContent, "what makes")
  const cardBgs = ['#EEFACA', '#E5EDEE', '#FBEEC9'];
  return (
    <Box background="#fff">
      <Box
        w="100%"
        maxW="1330px"
        mx="auto"
        py={{ base: '32px', md: '30px' }}
        px={{ base: '15px', md: '0' }}
        bg="#fff"
      >
        <Box
          maxW={{ base: 290, md: '100%' }}
          mx="auto"
          width="100%"
          mt={{ base: '0', md: '0' }}
          mb={{ base: '35px', md: '40px' }}
        >
          <SingleLineText sectionContent={sectionContent?.[0]} />
          <SingleLineText sectionContent={sectionContent?.[1]} />
        </Box>
        <Box>
          <Flex
            flexWrap="wrap"
            flexDir="row"
            w="100%"
            alignItems="center"
            justifyContent="center"
            gridGap={{ base: '0px', sm: '20px' }}
          >
            {sectionContent[2].card.map((cd, id) => {
              return <FlipCard cd={cd} key={nanoid()} bg={cardBgs[id]} />;
            })}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default WhatMakes;
