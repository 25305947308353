/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import CommonComponents from '@ihs/Common/CommonComponents';
import StyleConfig from '@ihs/Common/StyleConfig';
import Zigzag1Row from '@ihs/components/Cards/Zigzag1Row';
import HomeTestimonials from '@ihs/sections/HomeTestimonials';
import IhsIntro from '@ihs/sections/IhsIntro';
import OurValues from '@ihs/sections/OurValues';
import WhatMakes from '@ihs/sections/WhatMakes';

/**
 * Renders Render IHS Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render IHS Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  pageData,
  wisdomData
}) => {
  const { sectionId } = section;

  // console.log("testimonial testing", section);
  if (sectionId === 'zigzag-1-row') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Zigzag1Row sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'what-makes-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <WhatMakes
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  }

  else if (sectionId === 'home-testimonials') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <HomeTestimonials
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  }
  else if (sectionId === 'our-values') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <OurValues
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  }

  else if (sectionId === "ihs-intro") {
    return (
      <IhsIntro sectionContent={section.sectionContent} section={section} region={region} lang={lang} />
    )
  } else {
    return (
      <Box bg="#fff">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
            return (
              <StyleConfig
                id={section?.sectionId}
                key={section?.sectionId}
                style={section?.styleConfig}
                section={section}
                pB={{ base: '0', md: '15px' }}
              >
                <CommonComponents
                  key={nanoid()}
                  sectionContent={secCon}
                  section={section}
                  region={region}
                  language={lang}
                  pType={pType}
                  csrData={csrData}
                  pageData={pageData}
                  wisdomData={wisdomData}
                  index={i}
                />
              </StyleConfig>
            );
          })
          : null}
      </Box>
    );
  }
};

export default RenderSections;
