/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Components */
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Styles */
/* Services */

const FlipCard = ({ cd, bg }) => {
    const [hovered, setHovered] = useState(false);
    const [icon, setIcon] = useState(false);
    // const ArrowRight = chakra(FaArrowRight);
    const boldFont = {
        fontFamily: "'Zilla Slab', serif",
        fontWeight: '600',
        textAlign: 'center',
    };
    const cardBox = {
        w: '100%',
        height: '100%',
        position: 'absolute',
    };
    const backStStyle = {
        color: '#3B444B',
        fontFamily: "'Zilla Slab', serif",
        fontSize: { base: '18px', md: '16px' },
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: { base: '28px', md: '26px' },
    };

    return (
        <Box
            background="transparent"
            className="flipCard"
            w={{ base: '330px', sm: '330px', md: '240px', lg: '330px' }}
            height={{ base: '470px', md: '412px', lg: '470px' }}
            sx={{ perspective: '1000px' }}
            style={{ perspective: '1000px' }}
            borderRadius="15px 15px 0 0"
            mb="22px"
        >
            <Box
                className="flipCardInner"
                borderRadius="15px 15px 0 0"
                pos="relative"
                width="100%"
                height="100%"
                transition="transform 0.6s"
                sx={{ transformStyle: 'preserve-3d' }}
                transform={hovered ? 'rotateY(180deg)' : 'unset'}
            >
                <Box
                    className="flipCardFront"
                    borderRadius="15px 15px 0 0"
                    bg={bg}
                    h="100%"
                    w="100%"
                    {...cardBox}
                    style={{ WebkitBackfaceVisibility: 'hidden' }}
                    sx={{
                        WebkitBackfaceVisibility: 'hidden',
                        backfaceVisibility: 'hidden',
                    }}
                >
                    <Flex
                        borderRadius="15px 15px 0 0"
                        maxH="100%"
                        h="100%"
                        overflow="hidden"
                        flexDir="column"
                        justifyContent="space-between"
                    >
                        <Box p="23px 23px 0">
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                flexDir="column"
                                className="cardContent"
                            >
                                <Heading
                                    as="h3"
                                    color="#032B43"
                                    maxW="180px"
                                    {...boldFont}
                                    fontSize="22px"
                                    fontStyle="normal"
                                    lineHeight="24px"
                                >
                                    {cd?.title}
                                </Heading>
                                <StructuredTextParser
                                    str={`<p>${cd?.description?.value?.document?.children[0]?.children[0]?.value.split(
                                        '.'
                                    )[0]
                                        }</p>`}
                                    sectionContent={cd}
                                    color="#3B444B"
                                    textAlign="center"
                                    fontFamily="'Zilla Slab', serif"
                                    fontSize="14px"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    mt="10px"
                                    lineHeight="19px"
                                    className="firstLine"
                                    noOfLines="3"
                                />
                            </Flex>
                            <Text
                                onMouseLeave={() => setIcon(false)}
                                onMouseEnter={() => setIcon(true)}
                                {...boldFont}
                                fontSize="18px"
                                cursor="pointer"
                                lineHeight="17px"
                                fontStyle="normal"
                                color="#DC6704"
                                _hover={{ color: '#622210' }}
                                textDecor="underline"
                                mt="16px"
                                onClick={() => setHovered(true)}
                            >
                                {cd?.linkText}{' '}
                                <Box as="span">
                                    <svg
                                        style={{ display: 'inline', position: 'absolute' }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.2487 4.16797L17.082 10.0013L11.2487 15.8346M2.91537 10.0013L16.6654 10.0013"
                                            stroke={icon ? '#622210' : '#DC6704'}
                                            stroke-width="1.70833"
                                        />
                                    </svg>
                                </Box>
                            </Text>
                        </Box>

                        <Image
                            src={cd?.thumbnail?.url}
                            maxW="100%"
                            maxH="100%"
                            objectFit="cover"
                        />
                    </Flex>
                </Box>

                <Box
                    bg={bg}
                    className="flipCardBack"
                    borderRadius="15px 15px 0 0"
                    {...cardBox}
                    style={{ WebkitBackfaceVisibility: 'hidden' }}
                    sx={{
                        WebkitBackfaceVisibility: 'hidden',
                        backfaceVisibility: 'hidden',
                    }}
                    transform="rotateY(180deg)"
                    px={{ base: '24.5px', md: '20px', lg: '24.5px' }}
                    py="40px"
                    h="100%"
                >
                    <CloseIcon
                        cursor="pointer"
                        onClick={() => setHovered(false)}
                        top="20px"
                        right="15px"
                        pos="absolute"
                    />
                    <StructuredTextParser
                        mt={{ base: '30px', md: '0', lg: '30px' }}
                        str={render(cd.description)}
                        {...backStStyle}
                        px="0"
                        maxW={{ base: '280px', md: '198px', lg: '280px' }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default FlipCard;
