/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  List,
  Text,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parse from 'html-react-parser';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the HomeTestimonials component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} HomeTestimonials component
 */

const HomeTestimonials = ({ sectionContent }) => {
  const [readMoreText, setReadMoreText] = useState(210);
  const [readMoreLink, setReadMoreLink] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const readMoreHandler = e => {
    e.preventDefault();
    setReadMoreText(-1);
    setReadMoreLink(true);
  };

  function reactComp(htmlString) {
    const reactElement = parse(htmlString);
    return reactElement;
  }

  const NextArrow = props => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        pos="absolute"
        textAlign={'center'}
        top={{ base: '100%', sm: '100%', md: '35%' }}
        zIndex="10"
        cursor="pointer"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        right={{ base: '20%', sm: 'none', md: '-25px' }}
        bgSize="13px"
        _before={{ display: 'none' }}
        _after={{ display: 'none' }}
      >
        <svg
          width="62"
          height="60"
          viewBox="0 0 62 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.9354 56.9018C28.0186 61.5066 8.77228 60.9025 1.80195 42.8054C-2.15578 32.519 0.623219 20.1867 8.00973 12.0054C14.6552 4.64307 30.1209 -3.09787 40.2005 1.26531C43.683 2.77966 48.1778 4.243 50.5513 7.2744C53.3787 10.8079 56.7108 14.3924 58.4265 18.6348C61.2538 25.5514 62.7172 35.2443 59.334 42.11C57.7177 45.3911 56.2543 49.6334 53.1236 51.7519C51.8106 52.5601 44.0374 57.2535 43.9354 56.9018C43.9354 56.9528 43.1782 57.1031 43.9354 56.9018Z"
            fill="#F4B24D"
          />
          <path
            d="M29.6211 33.3346L32.9544 30.0013L29.6211 26.668"
            stroke="#353535"
            stroke-width="1.70833"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        pos="absolute"
        textAlign={'center'}
        top={{ base: '100%', sm: '100%', md: '35%' }}
        zIndex="10"
        cursor="pointer"
        border="0"
        borderRadius="100%"
        w="54px"
        _before={{ display: 'none' }}
        _after={{ display: 'none' }}
        h="54px"
        left={{ base: '20%', sm: '25%', md: '-25px' }}
      >
        <svg
          width="62"
          height="60"
          viewBox="0 0 62 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.9354 56.9018C28.0186 61.5066 8.77228 60.9025 1.80195 42.8054C-2.15578 32.519 0.623219 20.1867 8.00973 12.0054C14.6552 4.64307 30.1209 -3.09787 40.2005 1.26531C43.683 2.77966 48.1778 4.243 50.5513 7.2744C53.3787 10.8079 56.7108 14.3924 58.4265 18.6348C61.2538 25.5514 62.7172 35.2443 59.334 42.11C57.7177 45.3911 56.2543 49.6334 53.1236 51.7519C51.8106 52.5601 44.0374 57.2535 43.9354 56.9018C43.9354 56.9528 43.1782 57.1031 43.9354 56.9018Z"
            fill="#F4B24D"
          />
          <path
            d="M32.957 33.3346L29.6237 30.0013L32.957 26.668"
            stroke="#353535"
            stroke-width="1.70833"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
        },
      },
    ],
    appendDots: dots => (
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        bottom={{ base: '9%', md: '5%' }}
      >
        <List margin="0px" h="8px">
          {dots}
        </List>
      </Box>
    ),
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const uniqueCateg = {};

  const duplicateArr = () => {
    forEach(sectionContent?.[2]?.testimonials, function (btnItem, index) {
      const catg = render(btnItem?.details?.value)
        .replace('<p>', '')
        .replace('</p>', '');
      if (!uniqueCateg[catg]) {
        uniqueCateg[catg] = { name: catg, startIndex: index };
      }
    });
  };
  duplicateArr();

  const handleOnClick = index => {
    sliderRef.current.slickGoTo(index);
    scrollToView(index);
  };

  const scrollToView = index => {
    if (isMobile && typeof window !== 'undefined') {
      const menuItem = document.getElementById(`btn-${index}`);
      if (menuItem) {
        menuItem.scrollIntoView({
          behaviour: 'smooth',
          inline: 'nearest',
          block: 'nearest',
        });
      }
    }
  };

  return (
    <Box
      w="full"
      bg="#fff"
      pl={{ base: '10px', md: '50px', xl: '0px' }}
      pr={{ base: '5px', md: '50px', xl: '0px' }}
      pb={{ base: '80px', md: '80px' }}
    >
      <Box mx="auto" maxW="1030px" w="full" pos="relative">
        <Box
          pb="20px"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box w="full">
            <Heading
              as="h1"
              fontSize={{ base: '28px', md: '42px' }}
              fontWeight="600"
              color="#484141"
              position="relative"
              pt={{ base: '100px', sm: '120px', lg: '220px' }}
            >
              {sectionContent?.[0]?.text}

              <LazyLoadImageComponent
                src={sectionContent?.[1]?.image?.url}
                w={{ base: '188px', sm: '361px', lg: '500.08px' }}
                alt=""
                h={{ base: '142px', sm: '170px', lg: '278px' }}
                position="absolute"
                right="0"
                objectFit="cover"
                objectPosition="center"
                top={{ base: 0, md: '65px' }}
              />
            </Heading>

            <Box
              className="no-scrollbar"
              my={{ base: '20px', md: '38px' }}
              overflow="auto"
              whiteSpace="nowrap"
              overscrollBehavior="contain"
            >
              {Object.values(uniqueCateg)?.map((item, index) => {
                let isCurrentActive = false;

                if (
                  activeSlide >= item.startIndex &&
                  activeSlide <
                    (Object.values(uniqueCateg)[index + 1]
                      ? Object.values(uniqueCateg)[index + 1]?.startIndex
                      : sectionContent?.[2]?.testimonials.length)
                ) {
                  isCurrentActive = true;
                }

                return (
                  <Button
                    bg={isCurrentActive ? '#FBAD18' : '#79643B1A'}
                    color={isCurrentActive ? '#484141' : '#767676'}
                    borderRadius="8px"
                    _hover={{ background: '#622210', color: '#fff' }}
                    fontSize="16px"
                    key={nanoid(4)}
                    onClick={() => {
                      handleOnClick(item.startIndex);
                    }}
                    outline="0"
                    px={{ base: '25px' }}
                    display="inline-block"
                    mx={{ base: '2px', md: '8px' }}
                    id={`btn-${item.startIndex}`}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box>
          <Slider
            ref={sliderRef}
            {...settings}
            style={{ height: '100%', width: '100%' }}
          >
            {sectionContent?.[2]?.testimonials?.map(item => (
              <Box position="relative" key={nanoid(10)} pb="50px">
                <Box
                  w="full"
                  display="flex"
                  flexDir={{ base: 'column', md: 'row' }}
                >
                  <Box
                    pos="relative"
                    mx="auto"
                    w={{ base: '248px', md: '342px' }}
                    h={{ base: '295px', md: '352px' }}
                  >
                    <Image
                      src={item?.photo?.url}
                      alt=""
                      objectFit="cover"
                      objectPosition="top center"
                      borderRadius="8px"
                      w="full"
                      h="100%"
                      mx="auto"
                    />
                    <Box
                      pos="relative"
                      h="183px"
                      bottom="100px"
                      px={{ base: '5%', md: '10%' }}
                      py="10px"
                      pt="15px"
                      width={{ base: '230px', md: '295px' }}
                      bgImage={{
                        base: 'https://static.sadhguru.org/d/46272/1700214415-group-1171277325_mob.png',
                        md: "url('https://static.sadhguru.org/d/46272/1699600114-group-1171277325.png')",
                      }}
                      bgRepeat="no-repeat"
                      bgPos="center"
                      display="flex"
                      flexDir="column"
                      justifyContent="center"
                      alignItems="center"
                      mx="auto"
                    >
                      <Text
                        color="#fff"
                        fontWeight="500"
                        fontSize={{ base: '16px', md: '22px' }}
                        textAlign="center"
                        lineHeight={{ base: '15px', md: '20px' }}
                      >
                        {item?.userName}
                      </Text>

                      <StructuredTextParser
                        str={render(item?.userDescription?.value)}
                        color="#fff"
                        fontSize={{ base: '14px', md: '16px' }}
                        mt="2px"
                        fontWeight="400"
                        textAlign="center"
                        lineHeight={{ base: '16px', md: '18px' }}
                      />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    w={{ md: '50%', lg: '62%', xl: '66%' }}
                  >
                    <Box fontStyle="italic" mt={{ base: '40px', md: '0px' }}>
                      <StructuredTextParser
                        str={render(item?.testimonialTitle.value)}
                        fontSize="28px"
                        lineHeight="32px"
                        fontWeight="500"
                        color="#9E7652"
                        px={{ base: '10px', md: '30px' }}
                      />
                    </Box>
                    <Box
                      mt="30px"
                      pl={{ base: '10px', md: '35px' }}
                      pr={{ base: '0', md: '50px' }}
                      textAlign="left"
                      fontSize="18px"
                      color="#3B444B"
                    >
                      {/* <StructuredTextParser
                                                str={render(item?.testimonialText?.value)
                                                    .replace('<p>', '<span>')
                                                    .replace('</p>', '</span>')
                                                    .slice(0, readMoreText)}
                                                fontSize="18px"
                                                color="#3B444B"
                                                px={{ base: "10px", md: "50px" }}
                                            /> */}
                      {reactComp(
                        `${render(item?.testimonialText?.value)
                          .replace('<p>', '<span>')
                          .replace('</p>', '</span>')
                          .slice(0, readMoreText)}...`
                      )}
                      {!readMoreLink && (
                        <Link
                          px={{ base: '5px' }}
                          fontWeight="600"
                          color="#DC6704"
                          fontSize="18px"
                          textDecor="underline"
                          width="140px"
                          flexGrow={1}
                          _hover={{ color: '#622210' }}
                          href="#"
                          onClick={e => readMoreHandler(e)}
                        >
                          {/* <NextLink href="#" passHref legacyBehavior> */}
                          Read more
                          {/* </NextLink> */}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
          <Box
            fontSize="18px"
            display={{ base: '', md: 'none' }}
            position="absolute"
            textAlign="center"
            width="full"
            mx="auto"
            bottom="-10px"
          >
            {Math.ceil(activeSlide + 1)} /{' '}
            {sectionContent?.[2]?.testimonials.length}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeTestimonials;
