/* Built In Imports */
/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@ihs/components/UI/LazyLoadImageComponent';

/* Services */
/* Styles */

/**
 * Renders the Zigzag1Row Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const Zigzag1Row = ({ sectionContent, section }) => {
  //  console.log('Admission', sectionContent);
  return (
    <Flex
      flexDir="column"
      mt="60px"
      mb="95px"
      mx={{ base: '20px', xl: '36px' }}
      gridGap="38px"
      align="center"
    >
      {sectionContent?.map((section, index) => {
        const [card] = section.cards;
        return (
          <Flex
            key={nanoid(4)}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent="space-between"
            maxW="1300px"
            w="100%"
            p={{
              base: '36px 30px 45px',
              md: '36px 60px 75px 40px',
              xl: '40px 65px',
              '2xl': '49px 74px 69px 76px',
            }}
            gridColumnGap="95px"
            bg="#f5f5f5"
            borderRadius="1rem"
            boxShadow="28px 27px 25px -34px rgba(99,99,99,0.75) inset"
          >
            <Box
              order={index === 1 ? { base: '0', lg: '1' } : 'initial'}
              minW={{ base: '50%', lg: '305px' }}
              maxW={{ base: '720px', lg: '349px' }}
              mb={{ sm: '30px', base: '30px', lg: 'initial' }}
              flexShrink="0"
            >
              <LazyLoadImageComponent
                // alt={card?.thumbnails[0]?.url || ''}
                src={card?.thumbnails[0]?.url}
              />
            </Box>
            <Box w="100%" fontFamily="FedraSansStd-book" className="textP">
              <Box
                as="h3"
                mb="12px"
                fontSize="28px"
                fontFamily="FedraSansStd-A-medium"
                lineHeight="32px"
              >
                {card?.title}
              </Box>
              <StructuredText data={card?.description?.value?.document} />

              <Flex mt="27px" align="center" gridColumnGap="0.5rem">
                <Text fontFamily="FedraSansStd-book">{card?.infoText}</Text>
                {card?.buttonText && (
                  <Link
                    borderRadius="4px"
                    fontSize="18px"
                    target="_blank"
                    bg="#356190"
                    px="8px"
                    py="8px"
                    textDecoration="none"
                    fontFamily="FedraSansStd-A-medium"
                    color="#fff"
                    _hover={{ color: '#fff !important', background: '#c85333' }}
                    href={card?.buttonLink}
                  >
                    {card?.buttonText}
                  </Link>
                )}
              </Flex>
            </Box>
          </Flex>
        );
      })}
      <style jsx global>
        {`
          .textP p {
            padding-bottom: 15px;
          }
          .textP ul li {
            margin: 5px 15px;
          }
        `}
      </style>
    </Flex>
  );
};

export default Zigzag1Row;
